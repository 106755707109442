import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { getManualBookingHistory } from "../../../redux/zsports_slice/manual_booking_slice";
import { useDispatch, useSelector } from "react-redux";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import Loader from "../../components/loader";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";

export default function ManualBookingScreen() {
      const navigate = useNavigate();
    
  const manualBookingHistoryReducer = useSelector(
    (state) => state.manualBookingHistory || {}
  );
    // const sportsComplexReducer = useSelector(
    //   (state) => state.sportsComplex || {}
    // );
    // const { complexWithCitiesList, loading } = sportsComplexReducer;
  const { manualBookingHistoryList, loading } = manualBookingHistoryReducer;

  const [filteredList, setFilteredList] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [expandedRows, setExpandedRows] = useState(null);
  const dispatch = useDispatch();
  const [qrData, setQrData] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));


  const [isQrDialogVisible, setIsQrDialogVisible] = useState(false);

  const getStartOfYear = () => moment().startOf("year").toDate();
  const getEndOfYear = () => moment().endOf("year").toDate();

  const [startDate, setStartDate] = useState(getStartOfYear());
  const [endDate, setEndDate] = useState(getEndOfYear());

  useEffect(() => {
    if (user && user.mobileNumber) {
      const requestBody = {
        mobileNumber: user.mobileNumber,
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
      };

      dispatch(getManualBookingHistory(requestBody));
    }
  }, [dispatch, startDate, endDate]);

  useEffect(() => {
    if (manualBookingHistoryList && manualBookingHistoryList.zSportHistoryList) {
      const completedBookings = manualBookingHistoryList.zSportHistoryList.filter(
        (booking) => booking.status === "COMPLETED"
      );
      setFilteredList(completedBookings);
    }
  }, [manualBookingHistoryList]);

  const handleGlobalFilterChange = (e) => {
    const searchValue = e.target.value;
    setGlobalFilter(searchValue);
    const filteredData = manualBookingHistoryList.zSportHistoryList?.filter(
      (list) =>
        list.status === "COMPLETED" &&
        (list.location.toLowerCase().includes(searchValue.toLowerCase()) ||
          list.court.toLowerCase().includes(searchValue.toLowerCase()) ||
          list.sports.toLowerCase().includes(searchValue.toLowerCase()) ||
          list.mobileNumber.toLowerCase().includes(searchValue.toLowerCase()))
    );
    setFilteredList(filteredData);
  };

  const bookingDateTemplate = (rowData) => {
    return formatDate(rowData.bookingDate);
  };

  const scanDateTemplate = (rowData) => {
    const scanDate = rowData.timeSlots?.[0]?.scanDate;
    return scanDate ? formatDate(scanDate) : "N/A";
  };
  const formatDate = (value) => {
    return moment(value).format("MMMM Do YYYY");
  };

  const handleShowQrDetails = (qrString) => {
    setQrData(qrString);
    setIsQrDialogVisible(true);
  };

  const columnsData = [
    {
      field: "id",
      header: "Booking ID",
    },
    // {
    //   field: "transactionId",
    //   header: "Transaction ID",
    // },
    {
      field: "date",
      header: "Booking Date",
      body: bookingDateTemplate,
    },
    {
      field: "court",
      header: "Court Name",
    },
    {
      field: "location",
      header: "Complex Name",
    },
    {
      field: "mobileNumber",
      header: "User Mobile Number",
    },
    {
      field: "sports",
      header: "Sports",
    },
    // {
    //   field: "slotPrice",
    //   header: "Slot Price (PKR)",
    //   body: (rowData) => {
    //     const slot = rowData.timeSlots?.[0]; // Access the first slot
    //     if (slot) {
    //       const totalPrice = (slot.slotPrice || 0) + (slot.additionalCharges || 0);
    //       return `${totalPrice.toFixed(2)}`;
    //     }
    //     return "N/A";
    //   },
    // },
    // {
    //   field: "scanDate",
    //   header: "Scan Date",
    //   body: scanDateTemplate,
    // },
    {
      field: "status",
      header: "Status",
      body: (rowData) => {
        if (rowData.status === "COMPLETED") {
          return <Tag severity="success" value={rowData.status} />;
        }
        return null;
      },
    },
  ];

  const nestedColumnsData = [
    {
      field: "orderId",
      header: "Order ID",
    },
    {
      field: "slotName",
      header: "Slot Name",
    },
    {
      field: "slotPrice",
      header: "Slot Price (PKR)",
      body: (rowData) => {
        const totalPrice = (rowData.slotPrice || 0) + (rowData.additionalCharges || 0);
        return `${totalPrice.toFixed(2)}`;
      },
    },
    {
      field: "slotStatus",
      header: "Slot Status",
    },
    {
      field: "slotTime",
      header: "Slot Time",
    },
    // {
    //   field: "status",
    //   header: "Status",
    //   body: (rowData) => {
    //     if (rowData.scanStatus) {
    //       return (
    //         <Tag
    //           severity={
    //             rowData.scanStatus === "Un-Scanned" ? "warning" : "success"
    //           }
    //           value={rowData.scanStatus}
    //         />
    //       );
    //     }
    //     return null;
    //   },
    // },
    // {
    //   field: "scanDetails",
    //   header: "Scan Details",
    //   body: (rowData) => (
    //     <Button
    //       className="qrbutton"
    //       label="View Details"
    //       icon="pi pi-qrcode"
    //       onClick={() => handleShowQrDetails(rowData.qrString)}
    //     />
    //   ),
    // },
  ];

  const rowExpansionTemplate = (data) => {
    return (
      <div className="p-3">
        <DataTable value={data.timeSlots}>
          {nestedColumnsData.map((col, index) => (
            <Column
              key={index}
              field={col.field}
              header={col.header}
              body={col.body}
            />
          ))}
        </DataTable>
      </div>
    );
  };

  const renderQrDialogContent = (qrString) => {
    const qrDetails = qrString.split("\r\n").map((line) => {
      const [key, ...rest] = line.split(":");
      const value = rest.join(":").trim();
      return {
        key: key?.trim(),
        value: value || "Zindigi QR Details",
      };
    });

    return (
      <div
        style={{
          padding: "20px",
          backgroundColor: "#f9f9f9",
          borderRadius: "8px",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <table style={{ borderCollapse: "collapse" }}>
          <tbody>
            {qrDetails.map((detail, index) => (
              <tr
                key={index}
                style={{
                  borderBottom: "1px solid #e0e0e0",
                  padding: "8px",
                  backgroundColor:
                    detail.key === "Zindigi" ? "#8fcfcc54" : "transparent",
                }}
              >
                <td
                  style={{ fontWeight: "bold", padding: "10px", color: "#555" }}
                >
                  {detail.key}
                </td>
                <td
                  style={{ padding: "10px", color: "#333", textAlign: "left" }}
                >
                  {detail.value}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const exportBookingHistory = (bookingHistory) => {
    const exportData = bookingHistory
      .map((item) => {
        return item.timeSlots.map((slot) => ({
          "Transaction ID": item.transactionId,
          "Mobile Number": item.mobileNumber,
          Location: item.location,
          Address: item.address,
          Sport: item.sports,
          Court: item.court,
          "Slot Price": slot.slotPrice,
          Status: item.status,
          Fee: item.fee,
          "Booking Date": moment(item.bookingDate).format("YYYY-MM-DD"),
          "Slot Time": slot.slotTime,
          "Scan Status": slot.scanStatus,
        }));
      })
      .flat();

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(exportData);

    const headers = Object.keys(exportData[0] || {});
    worksheet["!cols"] = headers.map(() => ({ wpx: 120 }));

    XLSX.utils.book_append_sheet(workbook, worksheet, "Manual Booking History");
    XLSX.writeFile(workbook, "manual_booking_history.xlsx");
  };

  
  const handleEditAddFormNavigation = (rowData) => {
    console.log(rowData)
    navigate({
      pathname: "/manual-booking/edit-add-form",
      state: { formData: rowData },
    });
  };

  return (
    <>
      <Dialog
        header={
          <div
            className="popup-header header-icon"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <div className="popup-header-text">QR Details</div>
            <button
              onClick={() => setIsQrDialogVisible(false)}
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                fontSize: "28px",
              }}
            >
              &times;
            </button>
          </div>
        }
        // style={{ width: "500px" }}
        visible={isQrDialogVisible}
        onHide={() => setIsQrDialogVisible(false)}
      >
        {qrData ? renderQrDialogContent(qrData) : "No details available"}
      </Dialog>
      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="mbl_view "
              placeholder="Search"
              value={globalFilter}
              onChange={handleGlobalFilterChange}
            />
          </span>
        </div>

        <div className="col-12 md:col-10 justify-content-end filter-responsive">
          <Calendar
            showIcon
            value={startDate}
            onChange={(e) => setStartDate(e.value)}
            readOnlyInput
            placeholder="Start Date"
            className="mr-3"
          />
          <Calendar
            showIcon
            value={endDate}
            onChange={(e) => setEndDate(e.value)}
            readOnlyInput
            placeholder="End Date"
            minDate={startDate}
            className="mr-3"
          />
          <Button
            className="custom-btn export-excel"
            label="Export to Excel"
            icon="pi pi-window-maximize"
            onClick={() => exportBookingHistory(filteredList)}
          />
          <Button
                      label="Book Manual Slots"
                      icon="pi pi-plus"
                      className="p-button ml-3"
            onClick={() => handleEditAddFormNavigation()}

                    />
        </div>
      </div>

      <div className="card">
        {loading && <Loader />}
        <DataTable
          scrollable
          emptyMessage="No record found."
          value={filteredList}
          paginator
          rows={10}
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          className="custom-data-table"
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
        >
          <Column expander style={{ width: "3em" }} />
          {columnsData.map((data, index) => {
            return (
              <Column
                key={index}
                field={data.field}
                header={data.header}
                body={data.body}
              />
            );
          })}
        </DataTable>
      </div>
    </>
  );
}
