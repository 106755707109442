import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik, Form, ErrorMessage } from "formik";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import {
  createSportsComplex,
  getSportsComplexList,
} from "../../../../../redux/zsports_slice/zsports_slice";
import { getSportsComplexes } from "../../../../../redux/portal_slice";
import Loader from "../../../../components/loader";
import { Dropdown } from "primereact/dropdown";
import SportForm from "./sportForm";
import { format, parseISO, isValid } from "date-fns";
import validationSchema from "./schema";
import { ConfirmDialog } from "primereact/confirmdialog";
import { FieldArray } from "formik";
import { confirmDialog } from "primereact/confirmdialog";
import CustomImagePreview from "../../../../components/custom_imagepreview";

const formatTime = (time) => {
  if (time instanceof Date) {
    return format(time, "HH:mm:ss");
  }

  if (typeof time === "string") {
    if (time.includes("T")) {
      const parsedDate = parseISO(time);
      if (isValid(parsedDate)) {
        return format(parsedDate, "HH:mm:ss");
      }
    }

    if (/^\d{2}:\d{2}:\d{2}$/.test(time)) {
      return time;
    }
  }

  throw new Error(`Invalid time format: ${time}`);
};

const EditAddForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const portalReducer = useSelector((state) => state.portal || {});
  const sportsComplexReducer = useSelector(
    (state) => state.sportsComplex || {}
  );

  const { portalList } = portalReducer;
  const { sportsComplexList } = sportsComplexReducer;
  const loading = portalReducer.loading || sportsComplexReducer.loading;

  const [showDialog, setShowDialog] = useState(false);
  const [selectedSportImage, setSelectedSportImage] = useState("");

  const [initialValues, setInitialValues] = useState({
    sportComplexId: "",
    mobileNumber: "",
    complexName: "",
    cityId: "",
    address: "",
    location: "",
    image: "",
    availableSports: [
      {
        sportId: "",
        courts: [
          {
            courtName: "",
            courtImage: "",
            slots: {
              timePerSlot: "",
              daysAvailable: "",
              daysAvailableArray: [],
              refreshTime: "",
              amountPerSlot: "",
              startTime: "",
              totalSlots: "",
            },
            slotsData: [
              {
                slotTime: "",
                slotName: "",
                slotPrice: "",
                additionalCharges: "",
                isActive: true,
              },
            ],
          },
        ],
      },
    ],
  });

  useEffect(() => {
    dispatch(getSportsComplexList());
    dispatch(getSportsComplexes({}));
  }, [dispatch]);

  useEffect(() => {
    if (portalList?.length > 0) {
      const portalData = { ...portalList[0] };
      if (
        !portalData.availableSports ||
        portalData.availableSports.length === 0
      ) {
        portalData.availableSports = [
          {
            sportId: "",
            courts: [
              {
                courtName: "",
                courtImage: "",
                slots: {
                  timePerSlot: "",
                  daysAvailable: "",
                  daysAvailableArray: [],
                  refreshTime: "",
                  amountPerSlot: "",
                  startTime: "",
                  totalSlots: "",
                },
                slotsData: [
                  {
                    slotTime: "",
                    slotName: "",
                    slotPrice: "",
                    additionalCharges: "",
                    isActive: true,
                  },
                ],
              },
            ],
          },
        ];
      }

      setInitialValues(portalData);
    }
  }, [portalList]);

  const handleSubmit = async (values) => {
    // console.log("Success:", values);
    try {
      const formattedValues = {
        ...values,
        availableSports: values.availableSports.map((sport) => ({
          ...sport,
          courts: sport.courts.map((court) => ({
            ...court,
            slots: {
              ...court.slots,
              startTime: formatTime(court.slots.startTime),
              timePerSlot: court.slots.timePerSlot
                ? parseInt(court.slots.timePerSlot, 10)
                : 0,
              refreshTime: court.slots.refreshTime
                ? parseInt(court.slots.refreshTime, 10)
                : 0,
              amountPerSlot: court.slots.amountPerSlot
                ? parseInt(court.slots.amountPerSlot, 10)
                : 0,
            },
            slotsData: court.slotsData.map((slot) => ({
              ...slot,
              additionalCharges: slot.additionalCharges
                ? parseFloat(slot.additionalCharges)
                : 0,
            })),
          })),
        })),
      };

      const response = await dispatch(
        createSportsComplex(formattedValues)
      ).unwrap();

      if (response?.responseCode === "00") {
        showSuccessDialog();
      } else {
        console.error("Response Error:", response?.responseDescription);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCancel = (event) => {
    event.preventDefault();
    setShowDialog(false);
    navigate({
      pathname: "/schedule",
    });
  };

  const showSuccessDialog = () => {
    setShowDialog(true);
  };

  const dialogFooter = (
    <div>
      <Button label="Okay" icon="pi pi-check" onClick={handleCancel} />
    </div>
  );

  const handleSportChange = (e, setFieldValue, sportIndex) => {
    const selectedSport = sportsComplexList.find(
      (sport) => sport.id === e.value
    );
    setFieldValue(
      `availableSports[${sportIndex}].sportImage`,
      selectedSport ? selectedSport.sportImage : ""
    );
    setFieldValue(`availableSports[${sportIndex}].sportId`, e.value);
  };

  const addSport = (setFieldValue, values) => {
    const newSport = {
      sportId: "",
      courts: [
        {
          courtName: "",
          courtImage: "",
          slots: {
            timePerSlot: "",
            daysAvailable: "",
            daysAvailableArray: [],
            refreshTime: "",
            amountPerSlot: "",
            startTime: "",
            totalSlots: "",
          },
          slotsData: [
            {
              slotTime: "",
              slotName: "",
              slotPrice: "",
              additionalCharges: "",
              isActive: true,
            },
          ],
        },
      ],
    };

    // setFieldValue(`availableSports`, [...values.availableSports, newSport]);
    const updatedSports = [...values.availableSports, newSport];
    setFieldValue(`availableSports`, updatedSports);
    setTimeout(() => {
      const lastSportElement = document.getElementById(
        `sport-${updatedSports.length - 1}`
      );
      if (lastSportElement) {
        lastSportElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 0);
  };

  const confirm = (onAccept) => {
    confirmDialog({
      header: <div className="custom-header">Delete Sport</div>,
      icon: "pi pi-info-circle",
      message: <strong>Are you sure you want to delete this sport?</strong>,
      accept: onAccept,
      reject: () => {},
      acceptClassName: "custom-btn-red",
      rejectClassName: "custom-white-red",
      className: "center-buttons no-close-button",
    });
  };

  return (
    <>
      <ConfirmDialog
        header="Success"
        visible={showDialog}
        footer={dialogFooter}
        message={
          <div style={{ textAlign: "center", lineHeight: "1.5" }}>
            Successfully added sports complex.
            <br />
            It is now awaiting approval from the checker.
          </div>
        }
      />
      <div className="main-form">
        {loading && <Loader />}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={handleSubmit}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div className="form-heading">
                <h5>Add Courts in Sports Complex</h5>
              </div>

              <div className="p-fluid formgrid grid">
                <div className="field md:col-6 lg:col-6 xl:col-4 responsive-col disabled-classes">
                  <label>City Name</label>
                  <InputText
                    maxLength={30}
                    keyfilter={/^[a-zA-Z\s]*$/}
                    name="cityId"
                    value={values.cityName || ""}
                    disabled
                  />
                </div>
                <div className="field md:col-6 lg:col-6 xl:col-4 responsive-col disabled-classes">
                  <label>Complex Name</label>
                  <InputText
                    maxLength={30}
                    keyfilter={/^[a-zA-Z\s]*$/}
                    name="complexName"
                    value={values.complexName}
                    disabled
                  />
                </div>

                <div className="field md:col-6 lg:col-4 xl:col-4 responsive-col">
                  <label>Complex Image</label>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <InputText
                      value={values.image || "Choose complex image"}
                      style={{ paddingRight: "2.5rem" }}
                      readOnly
                    />
                    <div
                      style={{
                        position: "absolute",
                        right: "0.5rem",
                        top: "45%",
                        transform: "translateY(-50%)",
                        height: "2rem",
                        width: "2rem",
                        cursor: "pointer",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                    >
                      <CustomImagePreview
                        src={values.image || ""}
                        alt="Preview"
                      />
                    </div>
                  </div>
                </div>

                <div className="field md:col-6 lg:col-6 xl:col-4 responsive-col">
                  <label>
                    Address <span className="error-indicator required">*</span>
                  </label>
                  <InputText
                    maxLength={50}
                    placeholder="Address"
                    name="address"
                    value={values.address}
                    onChange={(e) => setFieldValue("address", e.target.value)}
                  />
                  <ErrorMessage
                    name="address"
                    component="small"
                    className="error-message"
                  />
                </div>
                {/* <div className="field md:col-6 lg:col-6 xl:col-3 responsive-col">
                  <label>
                    Location <span className="error-indicator required">*</span>
                  </label>
                  <InputText
                    maxLength={100}
                    placeholder="Location"
                    name="location"
                    value={values.location}
                    onChange={(e) => setFieldValue("location", e.target.value)}
                  />
                  <ErrorMessage
                    name="location"
                    component="small"
                    className="error-message"
                  />
                </div> */}
              </div>
              <br />
              <div
                style={{
                  position: "sticky",
                  top: "60px",
                  zIndex: "1",
                  background: "#fff",
                }}
              >
                <div
                  className="form-heading"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <h5 style={{ marginBottom: "0" }}>
                      Sport ({values?.availableSports?.length})
                    </h5>
                    <div style={{ display: "flex", gap: "10px" }}>
                      {values.availableSports.map((data, sportIndex) => {
                        const sportId = sportsComplexList?.find(
                          (sport) => sport.id === data.sportId
                        )?.id;

                        return sportsComplexList?.find(
                          (sport) => sport.id === data.sportId
                        )?.sportImage ? (
                          <a
                            key={sportId}
                            // to={`#${sportId}`}
                            href={`#${sportId}`} // Optional for better accessibility
                            onClick={(e) => {
                              e.preventDefault();
                              const element = document.getElementById(
                                `sport-${sportIndex}`
                              );
                              if (element) {
                                const offset = 200; // Your defined offset
                                const elementPosition =
                                  element.getBoundingClientRect().top +
                                  window.scrollY; // Calculate position
                                const offsetPosition = elementPosition - offset; // Adjust for offset
                                // Use requestAnimationFrame for smooth scrolling
                                window.scrollTo({
                                  top: offsetPosition,
                                  behavior: "smooth",
                                });
                              }
                            }}
                          >
                            <img
                              src={
                                sportsComplexList?.find(
                                  (sport) => sport.id === data.sportId
                                )?.sportImage
                              }
                              alt="Sport"
                            />
                          </a>
                        ) : (
                          ""
                        );
                      })}
                    </div>
                  </div>

                  <Button
                    type="button"
                    icon="pi pi-plus"
                    className="p-button-success court-button"
                    onClick={() => addSport(setFieldValue, values)}
                    disabled={
                      sportsComplexList?.length ===
                      values?.availableSports?.length
                    }
                  />
                </div>
                <br />
              </div>
              <FieldArray name="availableSports">
                {({ push, remove }) => (
                  <div>
                    {values.availableSports?.map((court, sportIndex) => (
                      <div
                        key={sportIndex}
                        style={{
                          backgroundColor: "#80808014",
                          borderRadius: "8px",
                          padding: "20px 10px",
                          marginBottom: "30px",
                        }}
                        id={`sport-${sportIndex}`}
                      >
                        <div id={court.sportId} className="p-fluid ">
                          <div className="field responsive-col">
                            <label
                              style={{
                                padding: "0px 20px",
                              }}
                            >
                              Sport Name{" "}
                              <span className="error-indicator required">
                                *
                              </span>{" "}
                            </label>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "0px 15px",
                                gap:'10px'
                              }}
                            >
                              <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
                                <Dropdown
                                  filter
                                  name={`availableSports[${sportIndex}].sportId`}
                                  placeholder="Select Sport"
                                  value={
                                    values.availableSports[sportIndex]?.sportId
                                  }
                                  options={sportsComplexList
                                    ?.filter(
                                      (sport) =>
                                        !values?.availableSports?.some(
                                          (selectedSport, index) =>
                                            selectedSport?.sportId ===
                                              sport?.id && index !== sportIndex
                                        )
                                    )
                                    .map((sport) => ({
                                      label: sport.sportName,
                                      value: sport.id,
                                    }))}
                                  onChange={(e) =>
                                    handleSportChange(
                                      e,
                                      setFieldValue,
                                      sportIndex
                                    )
                                  }
                                />
                                <ErrorMessage
                                  name={`availableSports[${sportIndex}].sportId`}
                                  component="small"
                                  className="error-message"
                                />
                              </div>

                              <Button
                                type="button"
                                icon="pi pi-minus"
                                className="p-button-danger court-button"
                                disabled={values?.availableSports?.length == 1}
                                // onClick={() => remove(sportIndex)}
                                onClick={() =>
                                  confirm(() => remove(sportIndex))
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <SportForm
                          sport={court ?? {}}
                          sportIndex={sportIndex}
                          setFieldValue={setFieldValue}
                          selectedSportImage={selectedSportImage}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </FieldArray>

              <div className="flex buttons">
                <Button
                  label="Cancel"
                  className="p-button-secondary"
                  onClick={handleCancel}
                />
                <Button label="Submit" className="p-button" type="submit" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default EditAddForm;
