import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../services/axios_api";
import { toast } from "react-toastify";

const initialState = {
  manualBookingHistoryList: [],
  loading: false,
  error: null,
  paymentSuccess: null,
  complexWithCitiesListManual: null,
};

// Async thunk for manual booking payment
export const manualPaymentBooking = createAsyncThunk(
  "manualBookingHistory/manualPayment",
  async (bookingData, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(
        `/PortalApp/ManualPayment`,
        bookingData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

// Async thunk for fetching manual booking history
export const getManualBookingHistory = createAsyncThunk(
  "manualBookingHistory/fetch",
  async ({ mobileNumber, startDate, endDate }, { rejectWithValue }) => {
    try {
      const requestData = { mobileNumber, startDate, endDate };
      const response = await apiClient.post(
        `/PortalApp/GetManualPaymentBookHistory`,
        requestData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

export const getSportsComplexsBooking = createAsyncThunk(
  "manualBookingHistorysportsComplex/fetch",
  async ({ bookingDate }, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(`PortalApp/GetMySportsComplex`, {
        params: { bookingDate },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

const manualBookingHistoryReducer = createSlice({
  name: "manualBookingHistory",
  initialState,
  reducers: {
    resetChangeStatus(state) {
      state.paymentSuccess = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getManualBookingHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getManualBookingHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.manualBookingHistoryList = action.payload;
      })
      .addCase(getManualBookingHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(manualPaymentBooking.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(manualPaymentBooking.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentSuccess = action.payload;
      })
      .addCase(manualPaymentBooking.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getSportsComplexsBooking.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSportsComplexsBooking.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        // console.log("API Response:", action.payload);
        if (action.payload.responseCode === "00") {
          state.complexWithCitiesListManual = action.payload.data;
        } else {
          state.complexWithCitiesListManual = [];
          console.error("Error:", action.payload.responseDescription);
          toast.error(
            action.payload.responseDescription ||
              "An error occurred while fetching sports complexes."
          );
        }
      })
      .addCase(getSportsComplexsBooking.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default manualBookingHistoryReducer.reducer;
export const { resetChangeStatus } = manualBookingHistoryReducer.actions;
