import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../services/axios_api";

const initialState = {
  auditLogsList: [],
  loading: false,
  error: null,
  totalRecords: 0, // Stores total number of records
  currentPage: 1, // Stores current page
  pageSize: 10, // Stores page size
  updateSuccess: null,
};

export const getAuditLogs = createAsyncThunk(
  "AuditLogs/fetch",
  async ({ pageNo, pageSize, startDate = "", endDate = "", logLevel = "", searchText = "" }, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(`/Admin/GetAppLogs`, {
        params: { pageNo, pageSize, startDate, endDate, logLevel, searchText },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

const auditLogsReducer = createSlice({
  name: "auditLogs",
  initialState,
  reducers: {
    resetChangeStatus(state) {
      state.addSuccess = undefined;
      state.updateSuccess = undefined;
      state.deleteSuccess = undefined;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setPageSize(state, action) {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAuditLogs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAuditLogs.fulfilled, (state, action) => {
        state.loading = false;
        state.auditLogsList = action.payload.data;
        state.totalRecords = action.payload.recordsTotal; 
      })
      .addCase(getAuditLogs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default auditLogsReducer.reducer;
export const { resetChangeStatus, setCurrentPage, setPageSize } = auditLogsReducer.actions;
