import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Password } from "primereact/password";
import { getMyProfile, updateProfile } from "../../../redux/portal_slice";
import Loader from "../../components/loader";
import CustomImagePreview from "../../components/custom_imagepreview";

const SettingsTeller = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const portalReducer = useSelector((state) => state.portal || {});
  const { portalList, loading } = portalReducer;
  const [previewUrl, setPreviewUrl] = useState(null);
  const [fileName, setFileName] = useState("No file chosen");

  useEffect(() => {
    dispatch(getMyProfile({}));
  }, [dispatch]);

  useEffect(() => {
    if (portalList) {
      setPreviewUrl(portalList?.profileImagePath || "");
      setFileName(portalList?.profileImagePath);
    }
  }, [portalList]);

  const formik = useFormik({
    initialValues: {
      name: portalList?.name || "",
      email: portalList?.email || "",
      mobileNumber: portalList?.phoneNumber || "",
      pin: "",
      newPin: "",
      profileImagePath: portalList?.profileImagePath || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      pin: Yup.string().when("newPin", {
        is: (newPin) => newPin && newPin.length > 0,
        then: Yup.string().required(
          "Current PIN is required when providing a new PIN"
        ),
        otherwise: Yup.string().notRequired(),
      }),
    }),

    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const updatePayload = { id: portalList?.id, ...values };

      try {
        const action = updateProfile({ ...updatePayload });
        await dispatch(action).unwrap();
        resetForm();
        await dispatch(getMyProfile({})).unwrap();
      } catch (error) {
        console.error("Error in form submission:", error);
        console.error("Error details:", error.response?.data || error.message);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleFieldChange = (field, value) => {
    formik.setFieldValue(field, value);
  };

  const handleFileUpload = (event) => {
    const fileInput = event.target;
    if (fileInput.files.length > 0) {
      const file = fileInput.files[0];
      setFileName(file.name); 
      const reader = new FileReader();
      reader.onload = async () => {
        let base64String = reader.result;
        if (base64String.includes("base64,")) {
          base64String = base64String.split("base64,")[1];
        }
        setPreviewUrl(reader.result);
        handleFieldChange("profileImageBase64", base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCancel = (event) => {
    event.preventDefault();
    navigate({
      pathname: "/dashboard-teller",
    });
  };

  return (
    <>
      {loading && <Loader />}
      <div className="main-form">
        <div className="form-heading">
          <h5>Edit Details</h5>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="p-fluid formgrid grid">
            <div className="field md:col-6 lg:col-3 xl:col-4 responsive-col">
              <label htmlFor="name">
                Name <span className="error-indicator required">*</span>
              </label>
              <InputText
                id="name"
                name="name"
                placeholder="Enter name"
                value={formik.values.name}
                onChange={(e) => handleFieldChange("name", e.target.value)}
              />
            </div>

            <div className="field md:col-6 lg:col-3 xl:col-4 responsive-col">
              <label htmlFor="pin">Pin</label>
              <Password
                toggleMask
                autoComplete="new-password"
                id="pin"
                name="pin"
                feedback={false}
                maxLength={4}
                placeholder="Enter pin"
                value={formik.values.pin}
                onChange={(e) => handleFieldChange("pin", e.target.value)}
                className={
                  formik.touched.pin && formik.errors.pin ? "p-invalid" : ""
                }
              />
              {formik.touched.pin && formik.errors.pin && (
                <small className="p-error">{formik.errors.pin}</small>
              )}
            </div>
            <div className="field md:col-6 lg:col-3 xl:col-4 responsive-col">
              <label htmlFor="newPin">New Pin</label>
              <Password
                toggleMask
                autoComplete="new-password"
                id="newPin"
                name="newPin"
                feedback={false}
                maxLength={4}
                placeholder="Enter New Pin"
                value={formik.values.newPin}
                onChange={(e) => handleFieldChange("newPin", e.target.value)}
              />
            </div>
            <div className="field md:col-6 lg:col-3 xl:col-4 responsive-col">
              <label htmlFor="email">Email</label>
              <InputText
                type="email"
                id="email"
                name="email"
                placeholder="Enter email"
                value={formik.values.email}
                onChange={(e) => handleFieldChange("email", e.target.value)}
              />
            </div>

            <div className="field md:col-6 lg:col-3 xl:col-4 responsive-col">
              <label htmlFor="ProfileImageFile">Profile Image</label>
              <div className="file-input">
                <label className="custom-file-label">
                  <input
                    name="ProfileImageFile"
                    type="file"
                    id="ProfileImageFile"
                    accept=".png, .jpg, .jpeg, .svg"
                    onChange={handleFileUpload}
                    className="hidden-file-input"
                  />
                  <span className="file-icon"></span>
                  <span className="file-text">
                    {fileName || "Choose Image"}
                  </span>
                  {previewUrl && (
                    <div className="image-preview-container-inner">
                      <CustomImagePreview
                        src={previewUrl}
                        alt="Preview"
                        className="review-icon"
                      />
                    </div>
                  )}
                </label>
              </div>
            </div>
          </div>
          <div className="flex buttons">
            <Button
              label="Cancel"
              className="p-button-secondary"
              onClick={handleCancel}
            />
            <Button
              label="Submit"
              className="p-button"
              type="submit"
              disabled={!formik.dirty}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default SettingsTeller;
