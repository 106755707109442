import React, { useRef, useEffect } from "react";
import { FieldArray } from "formik";
import { Button } from "primereact/button";
import CourtForm from "./courtForm";
import { confirmDialog } from "primereact/confirmdialog";

const SportForm = ({
  sport,
  sportIndex,
  setFieldValue,
  selectedSportImage,
}) => {
  const newCourtRef = useRef(null);
  const shouldScrollRef = useRef(false);

  const addCourt = () => {
    const newCourt = {
      courtName: "",
      courtImage: "",
      slots: {
        timePerSlot: "",
        daysAvailable: "",
        daysAvailableArray: [],
        refreshTime: "",
        amountPerSlot: "",
        startTime: "",
        totalSlots: "",
      },
      slotsData: [],
    };

    setFieldValue(`availableSports[${sportIndex}].courts`, [
      ...sport.courts,
      newCourt,
    ]);

    shouldScrollRef.current = true;
  };

  const sportImage = selectedSportImage || sport.sportImage;

  const confirm = (onAccept) => {
    confirmDialog({
      header: <div className="custom-header">Delete Court</div>,
      icon: "pi pi-info-circle",
      message: <strong>Are you sure you want to delete this court?</strong>,
      accept: onAccept,
      reject: () => {},
      acceptClassName: "custom-btn-red",
      rejectClassName: "custom-white-red",
      className: "center-buttons no-close-button",
    });
  };

  useEffect(() => {
    if (shouldScrollRef.current && newCourtRef.current) {
      newCourtRef.current.scrollIntoView({ behavior: "smooth" });
      shouldScrollRef.current = false;
    }
  }, [sport.courts.length]);

  return (
    <FieldArray name={`availableSports[${sportIndex}].courts`}>
      {({ push, remove }) => (
        <div>
          {sport?.courts?.map((court, courtIndex) => (
            <div
              key={courtIndex}
              className="court-section"
              ref={courtIndex === sport.courts.length - 1 ? newCourtRef : null}
            >
              <div
                className="form-heading d-flex"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0px 15px",
                }}
              >
                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  {" "}
                  {sportImage ? (
                    <img
                      src={sportImage}
                      alt="Sport"
                      style={{
                        borderRadius: "50px",
                        width: "40px",
                        height: "40px",
                        border: "2px solid #509793",
                        objectFit: "none",
                      }}
                    />
                  ) : (
                    ""
                  )}
                  <h5>Court {courtIndex + 1}</h5>
                </div>

                <div
                  className="button-group-right adddonbuttons"
                >
                  <Button
                    type="button"
                    icon="pi pi-plus"
                    className="p-button-success court-button"
                    onClick={addCourt}
                  />
                  <Button
                    type="button"
                    icon="pi pi-minus"
                    className="p-button-danger court-button"
                    disabled={sport.courts.length == 1}
                    // onClick={() => remove(courtIndex)}
                    onClick={() => confirm(() => remove(courtIndex))}
                  />
                </div>
              </div>
              <div className="court-content">
                <CourtForm
                  court={court}
                  courtIndex={courtIndex}
                  setFieldValue={setFieldValue}
                  sportIndex={sportIndex}
                />
                {/* <div className="button-group-right">
                  <Button
                    type="button"
                    icon="pi pi-plus"
                    className="p-button-success court-button"
                    onClick={addCourt}
                  />
                  <Button
                    type="button"
                    icon="pi pi-minus"
                    className="p-button-danger court-button"
                    disabled={sport.courts.length == 1}
                    // onClick={() => remove(courtIndex)}
                    onClick={() => confirm(() => remove(courtIndex))}
                  />
                </div> */}
              </div>
            </div>
          ))}
        </div>
      )}
    </FieldArray>
  );
};

export default SportForm;
