import { useState, useEffect, useRef } from "react";
import { AppTopbar } from "../app/features/components/app_top_bar";
import { AppConfig } from "../AppConfig";
import { Outlet, useLocation } from "react-router-dom";
import classNames from "classnames";
import { Tooltip } from "primereact/tooltip";
import PrimeReact from "primereact/api";
import "primereact/resources/primereact.css";
import { AppMenu } from "../app/features/components/app_menu";
import { CSSTransition } from "react-transition-group";
import { useUser } from "../app/services/user_context";

const DashboardLayout = ({ isLoggedIn, handleLogout, ...topbarProps }) => {
  const { user } = useUser();

  const copyTooltipRef = useRef();
  const location = useLocation();
  const [layoutMode, setLayoutMode] = useState("static");
  const [layoutColorMode, setLayoutColorMode] = useState("light");
  const [inputStyle, setInputStyle] = useState("outlined");
  const [ripple, setRipple] = useState(true);
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  PrimeReact.ripple = true;

  let menuClick = false;
  let mobileTopbarMenuClick = false;

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, "body-overflow-hidden");
    } else {
      removeClass(document.body, "body-overflow-hidden");
    }
  }, [mobileMenuActive]);

  useEffect(() => {
    copyTooltipRef &&
      copyTooltipRef.current &&
      copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  const onInputStyleChange = (inputStyle) => {
    setInputStyle(inputStyle);
  };

  const onRipple = (e) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onLayoutModeChange = (mode) => {
    setLayoutMode(mode);
  };

  const onColorModeChange = (mode) => {
    setLayoutColorMode(mode);
  };

  const onWrapperClick = (event) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onToggleMenuClick = (event) => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === "overlay") {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive((prevState) => !prevState);
        setMobileMenuActive(false);
      } else if (layoutMode === "static") {
        setStaticMenuInactive((prevState) => !prevState);
      }
    } else {
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const onMobileTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    event.preventDefault();
  };

  const isDesktop = () => {
    return window.innerWidth >= 992;
  };

  const addClass = (element, className) => {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  };

  const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  };

  const wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": layoutMode === "overlay",
    "layout-static": layoutMode === "static",
    "layout-static-sidebar-inactive":
      staticMenuInactive && layoutMode === "static",
    "layout-overlay-sidebar-active":
      overlayMenuActive && layoutMode === "overlay",
    "layout-mobile-sidebar-active": mobileMenuActive,
    "p-input-filled": inputStyle === "filled",
    "p-ripple-disabled": ripple === false,
    "layout-theme-light": layoutColorMode === "light",
  });

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  };

  const findCurrentRouteLabel = (location) => {
    const path = location.pathname;

    for (const group of menu) {
      if (group.to === path) {
        return group.label;
      }

      if (group.items) {
        for (const item of group.items) {
          if (path.startsWith(item.to)) {
            return item.label || group.label;
          }
        }
      }

      // Check for nested paths
      if (group.to && path.startsWith(group.to)) {
        return group.label;
      }
    }

    return "";
  };

  const menu = [
    {
      label: "Dashboard",
      to:
        user?.role === "Developer" ? "/dashboard-checker" : "/dashboard-teller",
      icon: require("../assets/sports_assests/dashboard.png"),
      // visible:
      //   user?.role === "Developer" &&
      //   user?.role?.permissions?.find(
      //     (p) => p.permissionName === "Dashboard"
      //   ) &&
      //   true,
    },
    {
      label: "User Management",
      to: "/user-management",
      visible: user?.role === "Developer",
      icon: require("../assets/sports_assests/sports.png"),
    },
    {
      label: "Schedule Management",
      icon: require("../assets/sports_assests/sports.png"),
      to: "/schedule",
      visible: user?.role !== "Developer",
    },
    {
      label: "Register Management",
      icon: require("../assets/sports_assests/sports.png"),
      to: "/register-checker",
      visible: user?.role === "Developer",
    },
    {
      label: "Schedule Management",
      icon: require("../assets/sports_assests/sports.png"),
      to: "/schedule-checker",
      visible: user?.role === "Developer",
    },
    {
      label: "Manual Booking",
      icon: require("../assets/sports_assests/history.png"),
      to: "/manual-booking",
      visible: user?.role !== "Developer",
    },
    {
      label: "Booking History",
      icon: require("../assets/sports_assests/history.png"),
      to: "/booking-history",
      visible: user?.role !== "Developer",
    },
    {
      label: "Audit Logs",
      icon: require("../assets/sports_assests/history.png"),
      to: "/audit-logs",
      visible: user?.role == "Developer",
    },
    {
      label: "Settings",
      icon: require("../assets/sports_assests/history.png"),
      to: user?.role === "Developer" ? "/settings-checker" : "/settings-teller",
      className: "settings-item",
    },
  ];

  const filteredMenu = menu.filter((item) => item.visible !== false);

  return (
    <>
      <div className={wrapperClass} onClick={onWrapperClick}>
        <Tooltip
          ref={copyTooltipRef}
          target=".block-action-copy"
          position="bottom"
          content="Copied to clipboard"
          event="focus"
        />

        <div className="layout-sidebar" onClick={onSidebarClick}>
          <AppMenu
            model={filteredMenu}
            layoutColorMode={layoutColorMode}
            onMenuItemClick={onMenuItemClick}
          />
        </div>

        <div className="layout-main-container">
          <AppTopbar
            isLoggedIn={isLoggedIn}
            handleLogout={handleLogout}
            onToggleMenuClick={onToggleMenuClick}
            layoutColorMode={layoutColorMode}
            mobileTopbarMenuActive={mobileTopbarMenuActive}
            onMobileTopbarMenuClick={onMobileTopbarMenuClick}
            onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
            currentRouteLabel={findCurrentRouteLabel(location)}
          />
          <div className="layout-main">
            <Outlet />
          </div>
        </div>
      </div>
      <AppConfig
        rippleEffect={ripple}
        onRippleEffect={onRipple}
        inputStyle={inputStyle}
        onInputStyleChange={onInputStyleChange}
        layoutMode={layoutMode}
        onLayoutModeChange={onLayoutModeChange}
        layoutColorMode={layoutColorMode}
        onColorModeChange={onColorModeChange}
      />

      <CSSTransition
        classNames="layout-mask"
        timeout={{ enter: 200, exit: 200 }}
        in={mobileMenuActive}
        unmountOnExit
      >
        <div className="layout-mask p-component-overlay"></div>
      </CSSTransition>
    </>
  );
};

export default DashboardLayout;
