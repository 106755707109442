import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import CustomInputField from "../../components/custom_input_field";
import {
  registerUser,
  getCitiesList,
} from "../../../redux/auth_slice/register_user_slice";
import logo from "../../../../assets/sports_assests/z-logo.png";
import { useNavigate } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import "../../../../styles/file-input-custom.scss";
import CustomImagePreview from "../../components/custom_imagepreview";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";

export default function RegisterScreen() {
  const { loading, citiesList } = useSelector((state) => state.register);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCitiesList());
  }, [dispatch]);

  const [showDialog, setShowDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState(null);
  const [responseCode, setResponseCode] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [isTermsDialogVisible, setIsTermsDialogVisible] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false); // ✅ Local state for checkbox

  const formik = useFormik({
    initialValues: {
      mobileNumber: "",
      name: "",
      pin: "",
      email: "",
      address: "",
      sportsComplexName: "",
      cityId: "",
      sportsComplexAddress: "",
      sportsComplexImage: null,
      profileImageBase64: null,
    },
    validationSchema: Yup.object().shape({
      pin: Yup.string()
        .required("PIN is required.")
        .length(4, "PIN must be 4 digits long."),
      name: Yup.string().required("Name is required."),
      sportsComplexName: Yup.string().required("Complex Name is required."),
      cityId: Yup.string().required("City is required"),
      email: Yup.string().required("Email is required"),
      sportsComplexImage: Yup.mixed().required("Complex Image is required"),
      sportsComplexAddress: Yup.string().required(
        "Complex Address is required"
      ),
      // address: Yup.string().required(
      //   "Address is required"
      // ),
      mobileNumber: Yup.string()
        .required("Mobile Number is required.")
        .matches(
          /^03\d{9}$/,
          "Mobile Number must start with 03 and be 11 digits long."
        )
        .length(11, "Mobile Number must be 11 digits long."),
    }),
    onSubmit: async (data) => {
      if (!termsChecked) {
        toast.warning(
          "Please accept the Terms and Conditions before proceeding."
        );
        return;
      }

      dispatch(registerUser(data)).then((res) => {
        const responseCode = res?.payload?.responseCode;
        const responseDescription = res?.payload?.responseDescription;
        setResponseCode(responseCode);

        if (responseCode === "00") {
          setDialogMessage(
            "Registered Successfully!<br/>It is now awaiting approval from the checker."
          );
          showSuccessDialog();
        } else if (responseCode === "700") {
          const message =
            typeof responseDescription === "string"
              ? responseDescription
              : JSON.stringify(responseDescription);
          setDialogMessage(message);
          showSuccessDialog();
        } else {
          const errorMessage =
            responseDescription ||
            "An unexpected error occurred. Please try again later.";
          toast.error(errorMessage);
        }
      });
    },
  });

  const showSuccessDialog = () => {
    setShowDialog(true);
  };

  const handleRedirect = () => {
    navigate("/login");
  };

  const dialogFooter = (
    <div>
      <Button label="Okay" icon="pi pi-check" onClick={handleRedirect} />
    </div>
  );

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const TARGET_WIDTH = 640;
          const TARGET_HEIGHT = 480;

          const aspectRatio = img.width / img.height;
          let newWidth, newHeight;

          if (img.width > img.height) {
            // Landscape image
            newWidth = TARGET_WIDTH;
            newHeight = Math.round(TARGET_WIDTH / aspectRatio);
          } else {
            // Portrait or square image
            newHeight = TARGET_HEIGHT;
            newWidth = Math.round(TARGET_HEIGHT * aspectRatio);
          }

          canvas.width = newWidth;
          canvas.height = newHeight;

          ctx.drawImage(img, 0, 0, newWidth, newHeight);

          canvas.toBlob(
            (blob) => {
              const resizedReader = new FileReader();
              resizedReader.onload = () => {
                let base64String = resizedReader.result;
                if (base64String.includes("base64,")) {
                  base64String = base64String.split("base64,")[1];
                }

                formik.setFieldValue("sportsComplexImage", base64String);
                setPreviewUrl(URL.createObjectURL(blob));
              };
              resizedReader.readAsDataURL(blob);
            },
            "image/jpeg",
            0.8
          );
        };

        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <ConfirmDialog
        header={responseCode === "00" ? "Success!" : "Become a Merchant First!"}
        visible={showDialog}
        style={{ maxWidth: "400px" }}
        footer={dialogFooter}
        message={
          <div
            style={{ textAlign: "center", lineHeight: "1.5" }}
            dangerouslySetInnerHTML={{ __html: dialogMessage }}
          />
        }
        closable={true}
      />

      <Dialog
        header={
          <div className="relative w-full text-center">
            <h4 className="m-0 fw-semibold">Terms and Conditions - Z-Sports</h4>
            <Button
              icon="pi pi-times"
              className="p-button-text p-button-rounded absolute right-0 dialog-force-icon"
              onClick={() => setIsTermsDialogVisible(false)}
            />
          </div>
        }
        visible={isTermsDialogVisible}
        onHide={() => setIsTermsDialogVisible(false)}
        closable
        className="dialog-size terms-dialog"
      >
        <iframe
          src="https://qa-zsports.appinsnap.com/Z-SportService/terms-merchant.html"
          style={{ width: "100%", height: "400px", border: "none" }}
          title="Terms and Conditions"
        />
      </Dialog>

      <div className="auth_main register-screen">
        <div className="content">
          <img src={logo} className="logo-img" />
          <div className="auth_login_box">
            <h2 className="auth-welcome m-0 text-align-center">Signup</h2>

            <form
              className=" p-fluid justify-content-center align-items-left mt-4"
              onSubmit={formik.handleSubmit}
            >
              <div className="p-fluid formgrid grid">
                <div className=" md:col-6 responsive-col">
                  <CustomInputField
                    keyfilter={/^[a-zA-Z\s]*$/}
                    maxLength={30}
                    label="Name"
                    required={true}
                    iden="name"
                    formik={formik}
                    placeHolder="Name"
                    type="text"
                    icon="pi-user"
                  />
                </div>
                <div className=" md:col-6 responsive-col">
                  <CustomInputField
                    label="Mobile Number"
                    iden="mobileNumber"
                    formik={formik}
                    placeHolder="Mobile Number"
                    type="text"
                    required={true}
                    maxLength={11}
                    onKeyUp={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      formik.setFieldValue("mobileNumber", e.target.value);
                    }}
                    icon="pi-mobile"
                  />
                </div>
                <div className=" md:col-6 responsive-col">
                  <CustomInputField
                    label="Pin"
                    iden="pin"
                    formik={formik}
                    placeHolder="Pin"
                    type="password"
                    required={true}
                    feedback={false}
                    maxLength={4}
                    onKeyUp={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      formik.setFieldValue("pin", e.target.value);
                    }}
                    icon="pi-lock"
                  />
                </div>
                <div className=" md:col-6 responsive-col">
                  <CustomInputField
                    label="Email"
                    iden="email"
                    formik={formik}
                    placeHolder="Email"
                    type="email"
                    required={true}
                    icon="pi-envelope"
                  />
                </div>
                <div className=" md:col-6 responsive-col">
                  <CustomInputField
                    maxLength={50}
                    label="Address"
                    iden="address"
                    formik={formik}
                    placeHolder=" Address"
                    type="text"
                    icon="pi-building-columns"
                  />
                </div>
                <div className=" md:col-6 responsive-col">
                  <CustomInputField
                    keyfilter={/^[a-zA-Z\s]*$/}
                    maxLength={30}
                    label="Complex Name"
                    iden="sportsComplexName"
                    required={true}
                    formik={formik}
                    placeHolder="Complex Name"
                    type="text"
                    icon="pi-user"
                  />
                </div>
                <div className=" md:col-6 responsive-col">
                  <CustomInputField
                    iden="cityId"
                    formik={formik}
                    type="dropdown"
                    label="City"
                    required={true}
                    placeHolder="Select City"
                    options={citiesList.map((city) => ({
                      label: city.cityName,
                      value: city.cityId,
                    }))}
                    optionLabel="label"
                    optionValue="value"
                    filter={true}
                    icon="pi-map-marker"
                  />
                </div>
                <div className=" md:col-6 responsive-col">
                  <CustomInputField
                    maxLength={50}
                    label="Sports Complex Address"
                    iden="sportsComplexAddress"
                    formik={formik}
                    placeHolder="Sports Complex Address"
                    type="text"
                    required={true}
                    icon="pi-building-columns"
                  />
                </div>
                <div className=" md:col-6 responsive-col">
                  <label
                    htmlFor="sportsComplexImage"
                    style={{ marginBottom: "5px" }}
                  >
                    Complex Image&nbsp;<span className="staric-custom">*</span>
                  </label>
                  <div className="file-input">
                    <input
                      name="sportsComplexImage"
                      type="file"
                      id="sportsComplexImage"
                      accept=".png, .jpg, .jpeg, .svg"
                      onChange={handleFileUpload}
                      className="choose-image-none"
                    />
                    {previewUrl && (
                      <div className="image-preview-container">
                        <CustomImagePreview
                          src={previewUrl}
                          alt="Preview"
                          className="review-icon"
                        />
                      </div>
                    )}
                    {formik.touched.sportsComplexImage &&
                    formik.errors.sportsComplexImage ? (
                      <small className="p-error">
                        {formik.errors.sportsComplexImage}
                      </small>
                    ) : null}
                  </div>
                </div>

                <div className=" md:col-12 responsive-col">
                  <div className="d-flex align-items-center gap-4">
                    <Checkbox
                      inputId="terms-and-conditions"
                      checked={termsChecked}
                      onChange={(e) => setTermsChecked(e.checked)}
                      className="checkbox-courts"
                    />
                    <label
                      className="p-checkbox-label"
                      style={{ marginLeft: "10px", marginTop: "10px" }}
                    >
                      I agree to the{" "}
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsTermsDialogVisible(true);
                        }}
                        className="terms-anchor"
                      >
                        Terms and Conditions
                      </a>
                    </label>
                  </div>
                </div>
              </div>

              <br />
              <Button
                type="submit"
                className="customButton"
                label="Signup"
                loading={loading}
              />
            </form>
            <p className="text-center mt-2 an-account">
              If you have an account? <a onClick={handleRedirect}>Login here</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
