import { Button } from "primereact/button";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();
  const location = useLocation(); 
  const user = JSON.parse(localStorage.getItem("user"));
  const isLoggedIn = Boolean(localStorage.getItem("token"));

  const redirectToHome = () => {
    if (isLoggedIn) {
      if (
        user?.role === "Developer" &&
        location.pathname !== "/dashboard-checker"
      ) {
        navigate("/dashboard-checker");
      } else if (
        user?.role === "Teller" &&
        location.pathname !== "/dashboard-teller"
      ) {
        navigate("/dashboard-teller");
      }
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="not-found-container">
      <h1 className="not-found-title">404 - Page Not Found</h1>
      <p className="not-found-message">
        Oops! The page you are looking for does not exist.
      </p>
      <Button
        label="Go to Home"
        icon="pi pi-home"
        className="p-button-rounded p-button-primary"
        onClick={redirectToHome}
      />
    </div>
  );
};

export default NotFoundPage;
