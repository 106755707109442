import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "primereact/button";
import CustomInputField from "../../components/custom_input_field";
import logo from "../../../../assets/sports_assests/z-logo.png";
import { toast } from "react-toastify";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { formatTo12Hour } from "../../components/helper";
import apiClient from "../../../services/axios_api";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../services/user_context";

export default function LoginScreen() {
  const navigate = useNavigate();
  const { login } = useUser();

  const [loading, setLoading] = useState(false);
  const [otpDialogVisible, setOtpDialogVisible] = useState(false);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [mobileNumber, setMobileNumber] = useState("");
  const [otpExpiry, setOtpExpiry] = useState("");
  const [otpExpired, setOtpExpired] = useState(false);
  const [remainingTime, setRemainingTime] = useState("");
  const [otpLoading, setOtpLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      mobileNumber: "",
      pin: "",
    },
    validationSchema: Yup.object().shape({
      pin: Yup.string()
        .required("PIN is required.")
        .length(4, "PIN must be 4 digits long."),
      mobileNumber: Yup.string()
        .required("Mobile Number is required.")
        .matches(
          /^03\d{9}$/,
          "Mobile Number must start with 03 and be 11 digits long."
        )
        .length(11, "Mobile Number must be 11 digits long."),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const payload = {
          mobileNumber: values.mobileNumber,
          pin: values.pin,
        };

        const response = await apiClient.post("/Account/login/portal", payload);
        const responseData = response.data;
        // console.log(responseData);

        if (responseData.responseCode === "00") {
          setMobileNumber(values.mobileNumber);

          const expiryTime = new Date(responseData.data.otpExpiry);
          setOtpExpiry(expiryTime);

          setOtpDialogVisible(true);
          setOtpExpired(false);
        } else {
          const errorMessage =
            responseData.responseDescription || "Failed to perform operation";
          toast.error(errorMessage);
        }
      } catch (error) {
        console.error("Error occurred:", error);
        const errorMessage =
          error.response?.data?.responseDescription ||
          "Failed to perform operation";
        toast.error(errorMessage);
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (!otpExpiry) return;

    const calculateRemainingTime = () => {
      const currentTime = new Date().getTime();
      const expiryTime = new Date(otpExpiry).getTime();

      const timeLeft = expiryTime - currentTime;
      if (timeLeft <= 0) {
        setOtpExpired(true);
        setRemainingTime("0:00");
      } else {
        const secondsLeft = Math.floor((timeLeft % (1000 * 60)) / 1000);
        const minutesLeft = Math.floor((timeLeft / (1000 * 60)) % 60);
        setRemainingTime(
          `${minutesLeft}:${secondsLeft < 10 ? "0" : ""}${secondsLeft}`
        );
      }
    };

    calculateRemainingTime();
    const intervalId = setInterval(calculateRemainingTime, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [otpExpiry]);

  const handleChange = (e, index) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);

    if (e.target.value && index < 3) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  const handleOtpSubmit = async () => {
    setOtpLoading(true);
    try {
      const otpPayload = {
        mobileNumber: mobileNumber,
        otp: Number(otp.join("")),
      };

      const otpResponse = await apiClient.post(
        "/Account/verify-otp/portal",
        otpPayload
      );

      const otpResponseData = otpResponse.data;
      // console.log(otpResponse.data, "otpDtaa");

      if (otpResponseData.responseCode === "00" && otpResponseData.data?.user) {
        const userRole = otpResponseData.data.user.role;
        const user = otpResponseData.data.user;
        // console.log(user, "above");

        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", otpResponseData.data.token);
        localStorage.setItem("refreshToken", otpResponseData.data.refreshToken);
        login(user, otpResponseData.data.token);
        if (userRole === "Developer") {
          // console.log(userRole);
          navigate("/dashboard-checker");
        } else if (userRole === "Teller") {
          navigate("/dashboard-teller");
        }

        setTimeout(() => {
          toast.success(
            `${
              otpResponseData.responseDescription ||
              "OTP Verified successfully!"
            } OTP Verified! Logged in successfully!`
          );
        }, 100);
      } else if (otpResponseData.responseCode === 400) {
        toast.error(otpResponseData.responseDescription || "Invalid OTP!");
      } else {
        console.error(
          "OTP Verification Error:",
          otpResponseData.responseDescription
        );
        toast.error("Failed to verify OTP.");
      }
    } catch (error) {
      console.error("OTP Verification error:", error);
      toast.error("Internal server error");
    } finally {
      setOtpLoading(false);
    }
  };

  const handleRedirect = () => {
    navigate("/register");
  };

  return (
    <>
      <Dialog
        header={
          <div
            className="popup-header header-icon"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <div className="popup-header-text">Enter OTP</div>
            <button
              onClick={() => {
                setOtpDialogVisible(false);
                setOtp(new Array(4).fill(""));
                setOtpExpired(false);
                setOtpExpiry(null);
              }}
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                fontSize: "28px",
              }}
            >
              &times;
            </button>
          </div>
        }
        visible={otpDialogVisible}
        // style={{ width: "350px" }}
        onHide={() => {
          setOtpDialogVisible(false);
          setOtp(new Array(4).fill(""));
          setOtpExpired(false);
          setOtpExpiry(null);
        }}
      >
        <div className="field">
          <label className="text-center">
            An OTP has been sent to your email address. Please enter it here
          </label>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
              marginTop: "20px",
            }}
          >
            {otp.map((digit, index) => (
              <InputText
                key={index}
                id={`otp-input-${index}`}
                value={digit}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                maxLength={1}
                style={{ width: "50px", textAlign: "center" }}
                inputMode="numeric"
              />
            ))}
          </div>
        </div>
        {otpExpired && (
          <p
            className="mx-auto text-center"
            style={{ color: "red", fontWeight: "bold" }}
          >
            OTP has expired. Please try again.
          </p>
        )}
        {otpExpiry && !otpExpired && (
          <p
            className="mx-auto text-center mb-0"
            style={{ color: "green", fontWeight: "bold" }}
          >
            OTP will expire at: {formatTo12Hour(otpExpiry)}
            <br /> (Remaining Time left: {remainingTime})
          </p>
        )}
        <div
          className="field"
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            loading={otpLoading}
            label="Verify OTP"
            className="custom-btn p-button"
            onClick={handleOtpSubmit}
            disabled={otp.some((digit) => digit === "")}
          />
        </div>
      </Dialog>

      <div className="auth_main">
        <div className="content">
          <img src={logo} className="logo-img" />
          <div className="auth_login_box">
            <h2 className="auth-welcome m-0 text-align-center">Login</h2>
            <form
              className=" p-fluid justify-content-center align-items-left mt-4"
              onSubmit={formik.handleSubmit}
            >
              <CustomInputField
                label="Mobile Number"
                iden="mobileNumber"
                formik={formik}
                placeHolder="Mobile Number"
                type="text"
                required={true}
                maxLength={11}
                onKeyUp={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  formik.setFieldValue("mobileNumber", e.target.value);
                }}
                icon="pi-mobile"
              />
              <div style={{ marginBottom: "20px" }}></div>
              <CustomInputField
                label="Pin"
                iden="pin"
                formik={formik}
                placeHolder="Pin"
                type="password"
                required={true}
                feedback={false}
                maxLength={4}
                onKeyUp={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  formik.setFieldValue("pin", e.target.value);
                }}
                icon="pi-lock"
              />
              <br />
              <Button
                loading={loading}
                type="submit"
                className="customButton"
                label="Log In"
                disabled={loading}
              />
            </form>
            <p className="text-center mt-2 an-account">
              If you have an account?{" "}
              <a onClick={handleRedirect}>Signup here</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
