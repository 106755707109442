import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSportsComplexList,
} from "../../../redux/zsports_slice/zsports_slice";
import { useNavigate } from "react-router-dom";
import "../../../../styles/customtable.scss";
import { InputText } from "primereact/inputtext";
import backIcon from "../../../../assets/sports_assests/back.png";
import Loader from "../../components/loader";
import { Calendar } from "primereact/calendar";
import {
  manualPaymentBooking,
  getSportsComplexsBooking,
} from "../../../redux/zsports_slice/manual_booking_slice";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { Button } from "primereact/button";
import { toast } from "react-toastify";

const EditAddFormManualBooking = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sportsComplexReducer = useSelector(
    (state) => state.sportsComplex || {}
  );
  const manualBookingHistoryReducer = useSelector(
    (state) => state.manualBookingHistory || {}
  );

  const { sportsComplexList, loading: sportsLoading } = sportsComplexReducer;
  const { complexWithCitiesListManual, loading: bookingHistoryLoading } =
    manualBookingHistoryReducer;

  const [selectedSport, setSelectedSport] = useState(null);
  const [selectedCourt, setSelectedCourt] = useState(null);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const today = moment().format("YYYY-MM-DD");
  const [bookingDate, setBookingDate] = useState(today);
  const [isWeekend, setIsWeekend] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);

  const handleDateChange = (e) => {
    const selectedDate = moment(e.value).format("YYYY-MM-DD");
    setBookingDate(selectedDate);

    const dayOfWeek = moment(selectedDate).day();
    setIsWeekend(dayOfWeek === 6 || dayOfWeek === 0);
  };

  const checkSports = (sport) => {
    const selectedData = complexWithCitiesListManual?.availableSports?.find(
      (item) => item.sportId === sport.id
    );
    setSelectedSport(selectedData || []);
    setSelectedCourt(null);
    setSelectedSlots([]);
  };

  // const checkCourts = (court) => {
  //   console.log(court)
  //   setSelectedCourt(court?.slotsData || null);
  //   setSelectedSlots([]);
  // };

  const checkCourts = (court) => {
    // console.log(court);
    const dayOfWeek = moment(bookingDate).day().toString();
    const availableDays = court?.slots?.daysAvailableArray?.map((day) =>
      day.trim()
    );

    if (availableDays && !availableDays.includes(dayOfWeek)) {
      setSelectedCourt([]);
    } else {
      setSelectedCourt(court?.slotsData || null);
    }
    setSelectedSlots([]);
  };

  const checkSlots = (slot) => {
    // console.log(slot);
    if (slot.slotStatus === "Booked") {
      return;
    }

    setSelectedSlots((prevSlots) =>
      prevSlots.includes(slot.slotId)
        ? prevSlots.filter((id) => id !== slot.slotId)
        : [...prevSlots, slot.slotId]
    );
  };

  useEffect(() => {
    dispatch(getSportsComplexList({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getSportsComplexsBooking({ bookingDate }));
  }, [dispatch, bookingDate]);

  useEffect(() => {
    setSelectedSport(null);
    setSelectedCourt(null);
    setSelectedSlots([]);
  }, [bookingDate]);

  const handleCancelClick = () => {
    navigate("/manual-booking");
  };

  const calculateTotalAmount = () => {
    return selectedSlots.reduce((total, slotId) => {
      const slot = selectedCourt?.find((s) => s.slotId === slotId);
      if (!slot) return total;

      // Weekend: slotPrice + additionalCharges
      if (isWeekend) {
        return total + (slot?.slotPrice || 0) + (slot?.additionalCharges || 0);
      }

      // Weekday:  slotPrice
      return total + (slot?.slotPrice || 0);
    }, 0);
  };

  useEffect(() => {
    setTotalAmount(calculateTotalAmount());
  }, [selectedSlots, selectedCourt, isWeekend]);

  const formik = useFormik({
    initialValues: {
      name: "",
      mobileNumber: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .matches(/^[a-zA-Z\s]*$/, "Only letters are allowed")
        .max(30)
        .required("Name is required"),
      mobileNumber: Yup.string()
        .matches(/^\d{11}$/, "Must be 11 digits")
        .required("Mobile number is required"),
    }),

    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const bookingData = {
        name: values.name,
        mobileNumber: values.mobileNumber,
        amount: totalAmount,
        date: bookingDate,
        email: values.email,
        complexId: selectedCourt?.[0]?.complexId || 0,
        courtId: selectedCourt?.[0]?.courtId || 0,
        sportId: selectedSport?.sportId || 0,
        slotIds: selectedSlots,
      };
      try {
        const response = await dispatch(
          manualPaymentBooking(bookingData)
        ).unwrap();

        if (response.responseCode === "00") {
          toast.success(response.responseDescription || "Booking successful!");

          resetForm();
          navigate("/manual-booking");
        } else {
          toast.error(response.responseDescription || "Booking failed!");
        }
      } catch (error) {
        toast.error("Something went wrong! Please try again.");
        console.error("Booking failed:", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <div className="main-form">
        {sportsLoading && <Loader />}
        {bookingHistoryLoading && <Loader />}

        <form onSubmit={formik.handleSubmit}>
          <div
            className="form-heading d-flex"
            style={{ alignItems: "center", gap: "10px" }}
          >
            <img
              src={backIcon}
              style={{
                width: "30px",
                height: "30px",
                marginTop: "3px",
                cursor: "pointer",
              }}
              onClick={handleCancelClick}
            />
            <h5>Manual Booking of Slots</h5>
          </div>

          <div className="mb-5">
            <h5>Select Date</h5>

            <Calendar
              showIcon
              value={moment(bookingDate).toDate()}
              onChange={handleDateChange}
              minDate={moment().toDate()}
              readOnlyInput
              placeholder="Select Booking Date"
              className="mr-3"
            />
          </div>

          {/* Select Sport */}
          <div>
            <h5>Select Sport</h5>

            <div className="sports-container">
              {sportsComplexList.map((sport) => (
                <div
                  key={sport.id}
                  className={`sport-item ${
                    selectedSport?.sportId === sport.id ? "active" : ""
                  }`}
                  onClick={() => checkSports(sport)}
                >
                  <img src={sport.sportImage} alt={sport.sportName} />
                  <span>{sport.sportName}</span>
                </div>
              ))}
            </div>
          </div>

          {/* Select Court (Only show if a sport is selected) */}
          {selectedSport && (
            <div>
              <h5>Select Court</h5>

              <div className="court-container">
                {Array.isArray(selectedSport?.courts) &&
                selectedSport.courts.length > 0 ? (
                  selectedSport.courts.map((court) => (
                    <div
                      key={court.id}
                      className={`court-card ${
                        selectedCourt === court.slotsData ? "active" : ""
                      }`}
                      onClick={() => checkCourts(court)}
                    >
                      <div className="image-container">
                        <img src={court.courtImage} alt={court.courtName} />
                      </div>
                      <div className="name">
                        <span>{court.courtName}</span>
                      </div>
                    </div>
                  ))
                ) : (
                  <span className="no-records">No court found</span>
                )}
              </div>
            </div>
          )}

          {/* Select Slots (Only show if a court is selected) */}

          {selectedCourt && (
            <div className="slotingcontainer">
              <h5>Select Slots</h5>

              <div className="slot-list">
                {Array.isArray(selectedCourt) && selectedCourt.length > 0 ? (
                  selectedCourt.map((slot) => (
                    <div
                      key={slot.id}
                      // className={`slot-card ${
                      //   selectedSlots.includes(slot.slotId) ? "active" : ""
                      // }`}
                      className={`slot-card 
                        ${selectedSlots.includes(slot.slotId) ? "active" : ""} 
                        ${slot.slotStatus === "Booked" ? "booked" : ""}`}
                      onClick={() => checkSlots(slot)}
                    >
                      <div className="slot-name">{slot.slotName}</div>
                      <div className="slot-time">{slot.slotTime}</div>
                      <div className="slot-price">
                        (Rs: &nbsp;
                        {slot.slotPrice.toLocaleString()})
                      </div>
                      {isWeekend && (
                        <div className="slot-additional">
                          add. charges: {slot.slotPrice.toLocaleString()}
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <span className="no-records">No slots found</span>
                )}
              </div>
            </div>
          )}

          {/* User Details - Show only when slot is selected */}
          {selectedSlots.length > 0 && (
            <div className="main-form">
              <h5>User Details</h5>
              <div className="p-fluid formgrid grid">
                <div className="field md:col-3 responsive-col">
                  <label htmlFor="name">Name</label>
                  <InputText
                    id="name"
                    maxLength={30}
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter name"
                  />
                  {formik.touched.name && formik.errors.name && (
                    <small className="p-error">{formik.errors.name}</small>
                  )}
                </div>
                <div className="field md:col-3 responsive-col">
                  <label htmlFor="mobileNumber">Mobile Number</label>
                  <InputText
                    id="mobileNumber"
                    maxLength={11}
                    name="mobileNumber"
                    value={formik.values.mobileNumber}
                    onChange={formik.handleChange}
                    placeholder="Enter mobile number"
                  />
                  {formik.errors.mobileNumber &&
                    formik.touched.mobileNumber && (
                      <small className="p-error">
                        {formik.errors.mobileNumber}
                      </small>
                    )}
                </div>

                <div className="field md:col-3 responsive-col">
                  <label htmlFor="email">Email</label>
                  <InputText
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.email && formik.touched.email && (
                    <small className="p-error">{formik.errors.email}</small>
                  )}
                </div>

                <div className="field md:col-3 responsive-col">
                  <label>Total Amount</label>
                  <InputText
                    value={totalAmount}
                    placeholder="Total Amount"
                    disabled
                  />
                </div>
              </div>

              <div className="flex buttons">
                <Button
                  label="Cancel"
                  className="p-button-secondary"
                  onClick={handleCancelClick}
                />
                <Button label="Submit" className="p-button" type="submit" />
              </div>
            </div>
          )}
        </form>
      </div>
    </>
  );
};

export default EditAddFormManualBooking;
