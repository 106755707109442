// src/App.js
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LoginScreen from "./app/features/screens/auth/loginscreen";
import DashboardLayout from "./layoutes/layout";
import NotFoundPage from "./app/features/components/not_found";
import DashboardCheckerScreen from "./app/features/screens/dashboard/dashboard-checker/dashboard";
import DashboardTellerScreen from "./app/features/screens/dashboard/dashboard-teller/dashboard";
import UserManagementScreen from "./app/features/screens/user-management/user-managemnet";
import AdminManagementScreen from "./app/features/screens/user-management/admin-management/admin-management";
import SettingsChecker from "./app/features/screens/settings/settings-checker";
import TellerManagementScreen from "./app/features/screens/user-management/teller-management/teller-management";
import SettingsTeller from "./app/features/screens/settings/settings-teller";
import BookingHistoryScreen from "./app/features/screens/booking-history/booking-history";
import ScheduleScreen from "./app/features/screens/zsports/schedule/schedule";
import EditAddForm from "./app/features/screens/zsports/schedule/form/edit_add_form";
import ViewDetails from "./app/features/screens/zsports/schedule/view-details/view_details";
import RegisterScreen from "./app/features/screens/auth/registerscreen";
import RegisterCheckerScreen from "./app/features/screens/zsports/register-management/register-checker";
import ViewDetailsRegister from "./app/features/screens/zsports/register-management/view-details";
import ScheduleCheckerScreen from "./app/features/screens/zsports/schedule-checker/schedule-checker";
import ViewDetailsChecker from "./app/features/screens/zsports/schedule-checker/view_details";
import { useUser } from "./app/services/user_context";
import {
  ProtectedRoute,
  PublicRoute,
} from "./app/features/components/hooks/protected_routes";
import "react-toastify/dist/ReactToastify.css";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "./styles/app.scss";
import "./styles/form.scss";
import "./styles/login.scss";
import "./styles/file-input-custom.scss";
import { ROUTES } from "./app/features/components/constants/routes";
import { ROLES } from "./app/features/components/constants/roles";
import ManualBookingScreen from "./app/features/screens/manual-booking/manual-booking";
import EditAddFormManualBooking from "./app/features/screens/manual-booking/edit_add_form";
import AuditLogsScreen from "./app/features/screens/audit-logs/audit_logs";

const App = () => {
  const { user } = useUser();

  return (
    <>
      <ToastContainer />
      <Routes>
        {/* Public Routes */}
        <Route
          path={ROUTES.LOGIN}
          element={
            <PublicRoute>
              <LoginScreen />
            </PublicRoute>
          }
        />
        <Route
          path={ROUTES.REGISTER}
          element={
            <PublicRoute>
              <RegisterScreen />
            </PublicRoute>
          }
        />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Navigate
                to={
                  user?.role === "Developer"
                    ? ROUTES.DASHBOARD_CHECKER
                    : user?.role === "Teller"
                    ? ROUTES.DASHBOARD_TELLER
                    : ROUTES.LOGIN
                }
              />
            </ProtectedRoute>
          }
        />

        {/* Protected Routes */}
        <Route
          element={
            <ProtectedRoute allowedRoles={[ROLES.DEVELOPER, "Teller"]}>
              <DashboardLayout />
            </ProtectedRoute>
          }
        >
          {/* Developer Routes */}
          <Route
            path="/dashboard-checker"
            element={
              <ProtectedRoute allowedRoles={["Developer"]}>
                <DashboardCheckerScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user-management"
            element={
              <ProtectedRoute allowedRoles={["Developer"]}>
                <UserManagementScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-management"
            element={
              <ProtectedRoute allowedRoles={["Developer"]}>
                <AdminManagementScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/teller-management"
            element={
              <ProtectedRoute allowedRoles={["Developer"]}>
                <TellerManagementScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/register-checker"
            element={
              <ProtectedRoute allowedRoles={["Developer"]}>
                <RegisterCheckerScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/register-checker/view-details/:id"
            element={
              <ProtectedRoute allowedRoles={["Developer"]}>
                <ViewDetailsRegister />
              </ProtectedRoute>
            }
          />
          <Route
            path="/schedule-checker"
            element={
              <ProtectedRoute allowedRoles={["Developer"]}>
                <ScheduleCheckerScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/schedule-checker/view-details/:id"
            element={
              <ProtectedRoute allowedRoles={["Developer"]}>
                <ViewDetailsChecker />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings-checker"
            element={
              <ProtectedRoute allowedRoles={["Developer"]}>
                <SettingsChecker />
              </ProtectedRoute>
            }
          />

          <Route
            path="/audit-logs"
            element={
              <ProtectedRoute allowedRoles={["Developer"]}>
                <AuditLogsScreen />
              </ProtectedRoute>
            }
          />

          {/* Teller Routes */}
          <Route
            path="/dashboard-teller"
            element={
              <ProtectedRoute allowedRoles={["Teller"]}>
                <DashboardTellerScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/schedule"
            element={
              <ProtectedRoute allowedRoles={["Teller"]}>
                <ScheduleScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/schedule/view-details/:id"
            element={
              <ProtectedRoute allowedRoles={["Teller"]}>
                <ViewDetails />
              </ProtectedRoute>
            }
          />

          <Route
            path="/schedule/edit-add-form"
            element={
              <ProtectedRoute allowedRoles={["Teller"]}>
                <EditAddForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/manual-booking"
            element={
              <ProtectedRoute allowedRoles={["Teller"]}>
                <ManualBookingScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/manual-booking/edit-add-form"
            element={
              <ProtectedRoute allowedRoles={["Teller"]}>
                <EditAddFormManualBooking />
              </ProtectedRoute>
            }
          />
          <Route
            path="/booking-history"
            element={
              <ProtectedRoute allowedRoles={["Teller"]}>
                <BookingHistoryScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings-teller"
            element={
              <ProtectedRoute allowedRoles={["Teller"]}>
                <SettingsTeller />
              </ProtectedRoute>
            }
          />
        </Route>

        {/* 404 Page */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};

export default App;
