// src/components/ProtectedRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "../../../services/user_context";

export const PublicRoute = ({ children }) => {
  const { isLoggedIn } = useUser();
  if (isLoggedIn) {
    return <Navigate to="/" />;
  }
  return children;
};

export const ProtectedRoute = ({ children, allowedRoles }) => {
  const { isLoggedIn, user, isLoading } = useUser();

  if (isLoading) {
    return <div>Loading...</div>; // Prevent redirecting before user data is loaded
  }

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  if (allowedRoles && !allowedRoles.includes(user?.role)) {
    return <Navigate to="/" />;
  }

  return children;
};
