import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { getAuditLogs } from "../../../redux/zsports_slice/audit_log_slice";
import Loader from "../../components/loader";
import moment from "moment";
import useDebounce from "../../components/hooks/useDebounce";
import Paginator from "../../components/paginator";
import { renderTextWithTooltip } from "../../components/helper";

export default function AuditLogsScreen() {
  const dispatch = useDispatch();
  const {
    auditLogsList = [],
    loading,
    totalRecords,
  } = useSelector((state) => state.auditLogs || {});

  const [globalFilter, setGlobalFilter] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const getStartOfYear = () => moment().startOf("year").toDate();
  const getEndOfYear = () => moment().endOf("year").toDate();

  const [startDate, setStartDate] = useState(getStartOfYear());
  const [endDate, setEndDate] = useState(getEndOfYear());
  const [page, setPage] = useState(1);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);

  const debouncedGlobalFilter = useDebounce(globalFilter, 500);

  useEffect(() => {
    dispatch(
      getAuditLogs({
        pageNo: page,
        pageSize: rows,
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        logLevel: "",
        searchText: debouncedGlobalFilter,
      })
    );
  }, [dispatch, page, rows, startDate, endDate, debouncedGlobalFilter]);

  useEffect(() => {
    setFilteredList(
      Array.isArray(auditLogsList) ? auditLogsList : auditLogsList?.data || []
    );
  }, [auditLogsList]);

  const handleGlobalFilterChange = (e) => {
    const searchValue = e.target.value;
    setGlobalFilter(searchValue);
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page + 1);
    setRows(event.rows);
  };

  const columnsData = [
    { field: "id", header: "ID" },
    {
      field: "clientIp",
      header: "Client IP",
      body: (rowData) => (rowData.clientIp?.trim() ? rowData.clientIp : "--"),
    },
    {
      field: "responseStatusCode",
      header: "Response Code",
      body: (rowData) =>
        rowData.responseStatusCode !== null &&
        rowData.responseStatusCode !== undefined &&
        rowData.responseStatusCode !== ""
          ? rowData.responseStatusCode
          : "N/A",
    },

    {
      field: "requestUrl",
      header: "Request Url",
      body: (rowData) =>
        renderTextWithTooltip(
          {
            ...rowData,
            requestUrl: rowData.requestUrl?.trim() ? rowData.requestUrl : "--",
          },
          "requestUrl",
          "bottom"
        ),
    },

    {
      field: "message",
      header: "Message",
      body: (rowData) =>
        renderTextWithTooltip(
          {
            ...rowData,
            message: rowData.message?.trim() ? rowData.message : "--",
          },
          "message",
          "bottom"
        ),
    },
    {
      field: "exceptionMessage",
      header: "Exception Message",
      body: (rowData) =>
        renderTextWithTooltip(
          {
            ...rowData,
            exceptionMessage: rowData.exceptionMessage?.trim()
              ? rowData.exceptionMessage
              : "--",
          },
          "exceptionMessage",
          "bottom"
        ),
    },

    {
      field: "date",
      header: "Date",
      body: (rowData) => moment(rowData.date).format("YYYY-MM-DD HH:mm A"),
    },
  ];

  return (
    <>
      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="mbl_view"
              placeholder="Search"
              value={globalFilter}
              onChange={handleGlobalFilterChange}
            />
          </span>
        </div>

        <div className="col-12 md:col-10 justify-content-end filter-responsive">
          <Calendar
            showIcon
            value={startDate}
            onChange={(e) => setStartDate(e.value)}
            readOnlyInput
            placeholder="Start Date"
            className="mr-3"
          />
          <Calendar
            showIcon
            value={endDate}
            onChange={(e) => setEndDate(e.value)}
            readOnlyInput
            placeholder="End Date"
            minDate={startDate}
            className="mr-3"
          />
        </div>
      </div>

      <div className="card">
        {loading && <Loader />}
        <DataTable
          scrollable
          emptyMessage="No record found."
          value={filteredList}
          className="custom-data-table"
        >
          {columnsData.map((col, index) => (
            <Column
              key={index}
              field={col.field}
              header={col.header}
              body={col.body}
            />
          ))}
        </DataTable>
        <Paginator
          first={first}
          rows={rows}
          totalRecords={totalRecords}
          onPageChange={onPageChange}
        />
      </div>
    </>
  );
}
