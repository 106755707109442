import React from "react";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useNavigate } from "react-router-dom";
import store, { resetState } from "../../store";
import imageIcon from "../../../assets/sports_assests/user-icon.png";
import { useUser } from "../../services/user_context";

export const AppTopbar = (props) => {
  const navigate = useNavigate();
  const { logout } = useUser();

  const user = JSON.parse(localStorage.getItem("user"));
  
  const signoutConfirm = () => {
    confirmDialog({
      message: "Are you sure you want to Logout?",
      icon: "pi pi-exclamation-triangle",
      header: "Confirmation",
      acceptClassName: "p-button p-button p-button-sm width-80",
      accept: () => {
        logout();
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("user");

        store.dispatch(resetState());

        navigate("/login");
      },
      reject: () => {},
    });
  };

  const handleCancel = (event) => {
    event.preventDefault();
    navigate({
      pathname: "/settings-teller",
    });
  };

  const handleImageError = (e) => {
    e.target.onerror = null;
    e.target.src = "https://placehold.co/500x500?text=No+Image";
  };

  return (
    <>
      <ConfirmDialog />

      <div className="layout-topbar">
        <div className="layout-topbar-ellipsis">
          <button
            type="button"
            className="p-link  layout-menu-button layout-topbar-button"
            onClick={props.onToggleMenuClick}
          >
            <i className="pi pi-bars" />
          </button>
          {props.currentRouteLabel && (
            <div className="layout-topbar-heading">
              {props.currentRouteLabel}
            </div>
          )}
        </div>

        <div className="layout-topbar-user">
          <div className="dropdown">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div className="profile-container">
                <img
                  onError={handleImageError}
                  src={
                    user?.profileImagePath ? user.profileImagePath : imageIcon
                  }
                  alt="Profile"
                  className="profile-image"
                />
                <div className="status-dot"></div>
              </div>
              <span className="mt-2"> Hello, &nbsp; {user?.name} &nbsp;</span>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <a className="dropdown-item" onClick={handleCancel}>
                  Settings
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  onClick={() => {
                    signoutConfirm();
                  }}
                >
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
